<template>
    <div class="page_d">

        <div class="pcShow">
            <div class="fullwidth-template">
                <div class="home-slider-banner">
                    <div class="container ydShow_padding0">
                        <div class="row12">
                            <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/1.jpg'"
                                alt="img" class="icon3">
                            <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/2.jpg'"
                                alt="img" class="icon3">
                            <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/3.jpg'"
                                alt="img" class="icon3">
                            <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/4.jpg'"
                                alt="img" class="icon3">
                            <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/5.jpg'"
                                alt="img" class="icon3">
                            <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/6.jpg'"
                                alt="img" class="icon3">
                            <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/7.jpg'"
                                alt="img" class="icon3">
                            <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/8.jpg'"
                                alt="img" class="icon3">
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="ydShow ydShow_padding0">
            <div class="fullwidth-template">
                <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/1.jpg'"
                    alt="img" class="icon3">
                <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/2.jpg'"
                    alt="img" class="icon3">
                <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/3.jpg'"
                    alt="img" class="icon3">
                <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/4.jpg'"
                    alt="img" class="icon3">
                <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/5.jpg'"
                    alt="img" class="icon3">
                <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/6.jpg'"
                    alt="img" class="icon3">
                <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/7.jpg'"
                    alt="img" class="icon3">
                <img :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/rule/' + country.countryName + '/8.jpg'"
                    alt="img" class="icon3">

            </div>
        </div>
        <div class="pcShow">
            <div class="food_btns">
                <div class="container d-flex">
                    <div class="food_btn_R pointer" v-if="clientInfo.verifyStatus == '02'" @click="layer = 1">{{
                        $t("message.JoinUs") }}</div>
                    <div class="food_btn_R pointer" v-else @click="clare()">{{ $t("message.JoinUsLogIn") }}</div>
                    <div class="food_btn_L pointer" @click="gocontactUs()" style="margin-left: 30px;">
                        <svg class="language-icon-yd">
                            <use xlink:href="#icon-contactUs2"></use>
                        </svg>
                        {{ $t("message.ContactUs") }}
                    </div>
                </div>
            </div>
        </div>
        <div class="ydShow"> 
            <div class="food_btns">
                <div class="food_btn_R pointer" v-if="clientInfo.verifyStatus == '02'" @click="layer = 3">{{
                    $t("message.JoinUs") }}</div>
                <div class="food_btn_R pointer" v-else @click="clare()">

                    {{ $t("message.JoinUsLogIn") }}
                </div>
                <div class="food_btn_L pointer" @click="gocontactUs()">
                    <svg class="language-icon-yd">
                        <use xlink:href="#icon-contactUs2"></use>
                    </svg>
                    {{ $t("message.ContactUs") }}
                </div>
            </div>
        </div>

        <div class="mask" v-if="layer == 1">
            <div class="layer1">
                <div class="ly_title">{{ $t("message.BoschModuleConcept") }}</div>
                <div class="scroll">
                    <img class="scroll_img" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/img2.png"
                        alt="" srcset="">
                </div>
                <div class="check_d d-flex align-items-center">
                    <img class="img4 pointer" @click="check = !check"
                        :src="check ? 'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/check.png' : 'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/check_no.png'"
                        alt="" srcset="">
                    {{ $t("message.IHaveReadAndAcceptedTheBoschModulePolicy") }}
                </div>

                <div class="pcbtn ly_btn pointer" @click="setcheckPC()">{{ $t("message.SignNow") }}</div>
                <div class="father_close">
                    <img class="close pointer" @click="layer = 0"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/close.png" alt="" srcset="">
                </div>
            </div>
        </div>
        <div class="mask" v-if="layer == 3">
            <div class="layer4">
                <div class="ly_title_yd">{{ $t("message.BoschModuleConcept") }}</div>
                <div class="scroll_yd">
                    <img class="scrollyd_img"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/img3.png" alt="" srcset="">
                </div>
                <div class="check_d d-flex align-items-center">
                    <img class="img4 pointer" @click="check = !check"
                        :src="check ? 'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/check.png' : 'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/check_no.png'"
                        alt="" srcset="">
                    {{ $t("message.IHaveReadAndAcceptedTheBoschModulePolicy") }}
                </div>
                <div class="pcbtn_yd pointer" @click="setcheck()">{{ $t("message.SignNow") }}</div>
                <div class="father_close_yd">
                    <img class="close_yd pointer"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/close.png" alt="" srcset=""
                        @click="layer = 0">
                </div>
            </div>
        </div>
        <div class="mask" v-if="layer == 2">
            <div class="layer2">
                <div class="ly_title">{{ $t("message.PleaseFillInTheBelowArea") }}</div>
                <input class="ly_ipt" type="text" maxlength="50" placeholder="Plaease fill in your full name"
                    v-model="bssApplyName">
                <div class="hint_text">
                    {{
                        $t("message.KindlyProvideSignatureOrFullName")
                    }}
                </div>
                <div class="pcbtn pointer" @click="bssImmediateSigned()">{{ $t("message.Submit") }}</div>
                <div class="father_close">
                    <img class="close pointer" @click="layer = 0"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/close.png" alt="" srcset="">
                </div>
            </div>
        </div>

        <div class="mask" v-if="layer == 4">
            <div class="layer3">
                <div class="ly_title_yd">{{ $t("message.PleaseFillInTheBelowArea") }}</div>
                <input class="ly_ipt_yd" type="text" maxlength="50" placeholder="Plaease fill in your full name"
                    v-model="bssApplyName">
                <div class="hint_text_yd">
                    {{
                        $t("message.KindlyProvideSignatureOrFullName")
                    }}
                </div>
                <div class="pcbtn_yd pointer" @click="bssImmediateSigned()" style="width: 300px;">{{
                    $t("message.Submit") }}</div>
                <div class="father_close_yd">
                    <img class="close_yd pointer"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/Module/close.png" alt="" srcset=""
                        @click="layer = 0">
                </div>
            </div>
        </div>


    </div>
</template>

<script>


export default {

    components: {
    },
    name: "Module",
    props: {
        msg: String,
    },
    data() {
        return {
            clientInfo: '',
            check: false,
            bssApplyName: '',
            layer: 0,
            country: '',
        }
    },
    created() {
        if (localStorage.getItem("client_info")) {
            this.clientInfo = JSON.parse(localStorage.getItem("client_info"))
            console.log('clientInfo==', this.clientInfo)
        }
        this.country = JSON.parse(localStorage.getItem("country"))

    },
    methods: {
        gocontactUs() {
            var that = this
            that.$router.push({
                name: "contactUs"
            }, () => {
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            })
        },
        clare() {
            var that = this
            that.$router.push({
                name: "login_index",
            });

        },
        bssImmediateSigned() {
            var that = this
            if (!this.bssApplyName) {
                that.$message.closeAll();
                that.$message({
                    message: that.$t("message.PlaeaseFillInYourFirstName"),
                    customClass: 'custom-message',
                    duration: 2000,
                    offset: window.screen.height / 2
                });
                return false
            }
            that.$flyNo.request({
                method: 'POST',
                url: that.httpUrl.signed_bssImmediateSigned,
                body: {
                    bssApplyName: that.bssApplyName
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.signed_bssImmediateSigned, '_res==', res)
                that.$message.closeAll();
                that.$message({
                    message: that.$t("message.YourApplicationIsPendingReview"),
                    customClass: 'custom-message',
                    duration: 2000,
                    offset: window.screen.height / 2
                });
                localStorage.setItem("signed_verifySigned_contractStatus", 1);
                that.$router.push({
                    name: "MyAccount",
                }, () => {

                })

            })
        },
        setcheckPC() {
            if (this.check) {
                this.layer = 2
            } else {
                this.$message.closeAll();
                this.$message({
                    message: this.$t("message.pleaseCheckTheBoschModulePolicy"),
                    customClass: 'custom-message',
                    duration: 2000,
                    offset: window.screen.height / 2
                });
            }
        },
        setcheck() {

            if (this.check) {
                this.layer = 4
            } else {
                this.$message.closeAll();
                this.$message({
                    message: this.$t("message.pleaseCheckTheBoschModulePolicy"),
                    customClass: 'custom-message',
                    duration: 2000,
                    offset: window.screen.height / 2
                });
            }
        },
        goBSSList() {
            var that = this
            localStorage.setItem("level", 1);
            that.$router.push({
                name: "BSSList"
            }, () => { })
        },
    }
}
</script>


<style lang="scss" scoped>
.pcShow .language-icon-yd {
    width: 32px;
    height:27px;
    margin-right: 5px;
}
.ydShow .language-icon-yd {
    width: 20px;
    height:17px;
    margin-right: 3px;
}
.fatherBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-bottom: 30px;
}

.fatherBtn_dy {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18.333vw;
    margin-bottom: 4vw;
}

.pcbtn {
    width: 317px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background-color: #007BC0;
    font-size: 16px;
    color: #fff;
    cursor: pointer
}

.pcbtn_dy {
    width: 100%;
    height: 10.867vw;
    line-height: 10.867vw;
    text-align: center;
    background-color: #007BC0;
    font-size: 16px;
    color: #fff;
    cursor: pointer
}

.nav_content {
    width: 100%;
    height: 15vw;
    color: #aacaeb;
    font-size: 5.467vw;
    background-color: #182c55;
    line-height: 15vw;
    text-align: center;
}

.mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

}

.layer1 {
    position: relative;
    box-sizing: border-box;
    width: 675px;
    height: 447px;
    background-color: #fff;
    padding: 30px 60px;
}

.ly_title {
    color: #000;
    font-size: 22px;
    font-weight: bold;
    line-height: 31px;
    text-align: center;
}

.scroll {

    width: 545px;
    height: 219px;
    white-space: nowrap;
    overflow: auto;
    margin-top: 20px;
}

.scroll_img {
    width: 545px;
    height: 216px;
}

.ly_btn {
    margin: 23px auto;
}

.father_close {
    width: 100%;
    display: flex;
    justify-content: center;
}

.close {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: -81px;
    left: auto;
    cursor: pointer;
    z-index: 10;
}

.layer2 {
    position: relative;
    box-sizing: border-box;
    width: 500px;
    height: 299px;
    background-color: #fff;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ly_ipt {
    width: 317px;
    height: 50px;
    background-color: #f3f3f3;
    border: none;
    padding-left: 50px;
    margin-top: 19px;
    border-radius: 10px;
}

input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #a2a2a2;
}

.hint_text {
    padding-top: 17px;
    width: 317px;
    line-height: 17px;
    font-size: 14px;
    color: #EB5336;
    margin-bottom: 20px;
}

.layer3 {
    position: relative;
    width: 322px;
    background-color: #fff;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ly_title_yd {
    margin-top: 21px;
    font-size: 4vw;
    font-weight: bold;
    height: 10vw;
    line-height: 8vw;
    text-align: center;
    color: #000;
}

.ly_ipt_yd {
    width: 298px;
    height: 50px;
    background-color: #f3f3f3;
    border: none;
    font-size: 3.467vw;
    padding-left: 6.667vw;
    border-radius: 1.333vw;
}

.hint_text_yd {
    padding-top: 17px;
    width: 290px;
    font-size: 3.2vw;
    line-height: 5.333vw;
    color: #EB5336;
    margin-bottom: 20px;
    line-height: 16px;
}

.pcbtn_yd {
    width: 202px;
    height: 10vw;
    line-height: 10vw;
    text-align: center;
    background-color: #007BC0;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    z-index: 2;
}

.father_close_yd {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.close_yd {
    width: 7.667vw;
    height: 7.667vw;
    position: absolute;
    bottom: -12.333vw;
    left: auto;
    cursor: pointer
}


.layer4 {
    position: relative;
    width: 90%;
    padding-bottom: 30px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10vw;
}

.scroll_yd {
    width: 72.667vw;
    height: 311px;
    white-space: nowrap;
    overflow: auto;
}

.scroll_yd::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.scroll_yd::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c1c1c1;
}

.pcbtn_yd2 {
    width: 86vw;
    height: 10vw;
    line-height: 10vw;
    text-align: center;
    background-color: #007BC0;
    font-size: 2.133vw;
    color: #fff;
    margin-top: 1.667vw;
}

.check_d {
    font-size: 14px;
    color: #007BC0;
    margin-bottom: 10px;
    width: 80%;
    line-height: 14px;
    margin: 10px 0;
}

.img4 {
    width: 20px;
    height: 20px;
    margin-right: 10px;

}
</style>