<template>
    <div class="header" @click="LanguageLayer = false">
        <div class="mask" v-if="LanguageLayer == true" @click="LanguageLayer = false"></div>
        <!-- <div class="pcShow" :style="{ 'height': offsetHeight + 'px' }"></div> -->
        <div class="pcShow" style="height: 161px"></div>
        <div class="ydShow" style="height: 67px "></div>
        <header class="header style7 pcShow header_top" ref="elementTop" style="border-bottom: 1px solid #D9D9D9;">
            <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon17.png" alt="img" class="icon17 ">
            <!--<img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon17.gif" alt="img" class="icon17 "> -->
            <div class="container ydShow_padding0">
                <!-- <div class="main-header d-flex " :class="scroll == 1 ? 'scrollTop' : scroll == 2 ? 'scrollbom' : 'headerH'"> -->
                <div class="main-header d-flex flex-column headerH">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class=" header-element pointer ydShow_padding0" @click="gohome(); switch_nav = 1;">
                            <div class="logo">
                                <svg class="icon-logo">
                                    <use xlink:href="#icon-logo"></use>
                                </svg>
                            </div>
                        </div>

                        <div class=" ydShow_padding0" v-if="country && pathName != 'index'">
                            <div class="header-control pointer">
                                <div
                                    class="block-minicart dungar-mini-cart block-header dungar-dropdown d-flex align-items-center">
                                    <!-- <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon1.png" alt="img"
                                        class="shopcart-icon"> -->
                                    <div @click="gocontactUs()" style="margin-right: 20px;">
                                        <svg class="shopcart-icon">
                                            <use xlink:href="#icon-contactUs"></use>
                                        </svg>
                                        <span>{{ $t("message.ContactUs") }}</span>
                                    </div>

                                    <div class="language-box" v-if="hostFlag && country.localLanguage">
                                        <svg class="language-icon" @click.stop="LanguageLayer = true">
                                            <use xlink:href="#icon-icon5"></use>
                                        </svg>
                                        <div class="select-box" v-if="LanguageLayer">
                                            <div class="select-title" @click.stop="LanguageLayer = true">
                                                {{ $t("message.SelectAlanguage") }}</div>
                                            <div class="select-li"
                                                :class="localLanguage != 'en' ? 'select-checkout' : ''"
                                                @click.stop="changeLanguage(1)">{{ country.countryName }}</div>
                                            <div class="select-li"
                                                :class="localLanguage == 'en' ? 'select-checkout' : ''"
                                                @click.stop="changeLanguage(2)">English</div>
                                        </div>
                                        <div class="triangle-box" v-if="LanguageLayer"></div>
                                    </div>
                                    <svg @click="goindex()" class="shopcart-icon">
                                        <use xlink:href="#icon-icon3"></use>
                                    </svg>
                                    <div style="margin-left: -5px;" @click="goindex()">{{
                                        country.countryNameAbbreviation
                                        }}</div>
                                    <svg class="shopcart-icon" @click="goMyAccount()">
                                        <use xlink:href="#icon-icon1"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div v-else class="col-lg-9 country_no">{{ $t("message.BOSCHAZPortal") }}</div>
                    </div>
                    <div class="row ydShow_padding0" v-if="country && pathName != 'index'">
                        <div class="d-flex justify-content-between align-items-center">

                            <ul class="nav-d">
                                <div class="nav-item pointer" @click="gohome(); switch_nav = 1;">
                                    <div :class="nav == 1 ? 'active' : ''">{{ $t("message.Home") }}</div>

                                </div>
                                <div class="nav-item pointer" @click="goWhatsOn(); switch_nav = 1;">
                                    <div :class="nav == 2 ? 'active' : ''">{{ $t("message.WhatSon") }}</div>

                                </div>
                                <div class="nav-item pointer" @click="goAuto_tech(); switch_nav = 1;">
                                    <div :class="nav == 3 ? 'active' : ''">{{ $t("message.TechWiki") }}</div>
                                </div>
                                <div class="nav-item pointer" @click="gomanual_uploading(); switch_nav = 1;" v-if="need_dealer_import == 1">
                                    <div :class="nav == 4 ? 'active' : ''">{{ $t("message.manualUploading") }}</div>

                                </div>
                                <div class="nav-item pointer" @click="goModule(); switch_nav = 1;" v-else>
                                    <div :class="nav == 4 ? 'active' : ''">{{ $t("message.Module") }}</div>

                                </div>
                               
                                <div class="nav-item pointer" @click="goMyAccount(); switch_nav = 1;">
                                    <div :class="nav == 5 ? 'active' : ''">{{ $t("message.MyAccount") }}</div>
                                </div>
                            </ul>
                            <div class="AutoParts">{{ $t("message.BOSCHAZPortal") }}</div>
                        </div>

                    </div>
                </div>
            </div>
        </header>
        <div class="header-device-mobile ydShow header_top" ref="elementTop2"
            :class="switch_nav == 2 ? 'switch_nav_d' : ''">
            <!-- <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon16.png" alt="img" class="icon16"> -->
            <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon16.gif" alt="img" class="icon16">
            <div class="wapper">
                <div class="item mobile-logo pointer" @click="gohome(); switch_nav = 1;">
                    <div class="logo">
                        <svg class="icon-logo">
                            <use xlink:href="#icon-logo"></use>
                        </svg>
                    </div>
                </div>
                <div class="flex1"></div>
                <div class="item" v-if="country && pathName != 'index'">
                    <div class="language-box-yd" v-if="hostFlag && country.localLanguage">

                        <svg class="language-icon-yd" @click.stop="LanguageLayer = true">
                            <use xlink:href="#icon-icon5"></use>
                        </svg>

                        <div class="select-box-yd" v-if="LanguageLayer">
                            <div class="select-title-yd" @click.stop="LanguageLayer = true">Select a language</div>
                            <div class="select-li-yd" :class="localLanguage != 'en' ? 'select-checkout' : ''"
                                @click.stop="changeLanguage(1)">{{ country.countryName }}</div>
                            <div class="select-li-yd" :class="localLanguage == 'en' ? 'select-checkout' : ''"
                                @click.stop="changeLanguage(2)">English</div>
                        </div>
                        <div class="triangle-box-yd" v-if="LanguageLayer"></div>
                    </div>
                    <svg class="menu-toggle icon2" @click="goindex()">
                        <use xlink:href="#icon-icon3"></use>
                    </svg>
                    <div style="margin-left: 0px;" @click="goindex()">{{ country.countryNameAbbreviation }}</div>
                    <svg class="menu-toggle icon2" @click="goMyAccount()">
                        <use xlink:href="#icon-icon1"></use>
                    </svg>
                    <svg class="menu-toggle icon2 pointer" v-if="switch_nav == 1" @click="switch_nav = 2">
                        <use xlink:href="#icon-icon2"></use>
                    </svg>
                    <svg v-if="switch_nav == 2" @click="switch_nav = 1"
                        class="menu-toggle icon2 animate__animated animate__rotateInDownLeft pointer">
                        <use xlink:href="#icon-icon4"></use>
                    </svg>

                </div>
                <div v-else class="country_no">{{ $t("message.BOSCHAZPortal") }}</div>
            </div>
            <div class="switch_nav_bg animate__animated animate__fadeIn" v-if="switch_nav == 2">
                <div class="switch_li d-flex align-items-center justify-content-between pointer"
                    @click="gohome(); switch_nav = 1;">
                    <div>{{ $t("message.Home") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
                <div class="switch_li d-flex align-items-center justify-content-between pointer"
                    @click="goWhatsOn(); switch_nav = 1;">
                    <div>{{ $t("message.WhatSon") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
                <div class="switch_li d-flex align-items-center justify-content-between pointer"
                    @click="goAuto_tech(); switch_nav = 1;">
                    <div>{{ $t("message.TechWiki") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
                <div class="switch_li d-flex align-items-center justify-content-between pointer" v-if="need_dealer_import == 1"
                    @click="gomanual_uploading(); switch_nav = 1;">
                    <div>{{ $t("message.manualUploading") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
                <div class="switch_li d-flex align-items-center justify-content-between pointer" v-else
                    @click="goModule(); switch_nav = 1;">
                    <div>{{ $t("message.Module") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
              

                <div class="switch_li d-flex align-items-center justify-content-between pointer"
                    @click="goMyAccount(); switch_nav = 1;">
                    <div>{{ $t("message.MyAccount") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
                <div class="switch_li d-flex align-items-center justify-content-between pointer"
                    @click="gocontactUs(); switch_nav = 1;">
                    <div>{{ $t("message.ContactUs") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
            </div>


        </div>
    </div>
</template>

<script>

import SvgIcon from "@/components/svgIcon/index.vue"
import "@/components/svgIcon/svg.js" // 批量导入svg文件
import { EventBus } from "../main"
export default {
    components: {
        SvgIcon
    },
    name: 'Header',
    props: {
        msg: String
    },
    data() {
        return {
            country: '',
            nav: 1,
            switch_nav: 1,
            clientInfo: '',
            scroll: -1,
            scrollTop: '',
            offsetHeight: '',
            pathName: '',
            localLanguage: '',
            LanguageLayer: false,
            hostFlag: false,
            need_dealer_import:'',
        }
    },
    watch: {
        $route(to, from, next) {
            var that = this
            that.scroll = -1
            const currentRoute = that.$router.currentRoute;
            that.pathName = currentRoute.name
            setTimeout(function () {
                // that.offsetHeight = that.$refs.elementTop.offsetHeight || that.$refs.elementTop2.offsetHeight
                // console.log('offsetHeight____________', that.offsetHeight)
                // that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            }, 800)
        },
    },
    created() {
        var that = this
        EventBus.$on('nav', (newValue) => {
            // 处理全局变量变化
            // console.log('nav=====', newValue);
            that.country = JSON.parse(localStorage.getItem("country")) || ''
            // console.log('that.country ====================', that.country)
            that.nav = newValue.nav
            localStorage.setItem("nav", that.nav)
            that.localLanguage = localStorage.getItem('localLanguage') || 'en'
            setTimeout(function () {
                that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
            }, 200)
            that.need_dealer_import = localStorage.getItem("need_dealer_import")|| 0
            console.log('need_dealer_import==============',that.need_dealer_import)

        })
        EventBus.$on('scrollTop', (newValue) => {
            that.scrollTop = newValue.scrollTop
            if (that.scrollTop >= 20) {
                if (that.scroll == 2 || that.scroll == -1) {
                    that.offsetHeight = 103
                    that.scroll = 1
                }

            } else {
                if (that.scroll == 1 || that.scroll == -1) {
                    that.offsetHeight = 163
                    that.scroll = 2
                }
            }
            // that.offsetHeight = that.$refs.elementTop.offsetHeight
        })

        this.localLanguage = localStorage.getItem('localLanguage') || 'en'
        this.hostFlag = localStorage.getItem('host') === 'http://ap-portal.boschaftermarket.com/bosch-enh5-api/api' ? false : true
    },
    mounted() {
        var that = this
        that.country = JSON.parse(localStorage.getItem("country")) || ''
        // console.log('elementTop==', this.$refs.elementTop.offsetHeight)
        this.offsetHeight = this.$refs.elementTop.offsetHeight
        this.need_dealer_import = localStorage.getItem("need_dealer_import")|| 0
        const currentRoute = this.$router.currentRoute;
        this.pathName = currentRoute.name
        // console.log('this.pathName===', this.pathName)
    },
    beforeDestroy() {
        EventBus.$off('nav')
    },
    methods: {
       
        changeLanguage(num) {
            this.LanguageLayer = false
            const country = JSON.parse(localStorage.getItem("country")) || ''
            if (!country) return
            if (num == 1) {
                this.localLanguage = country.localLanguage
            } else {
                this.localLanguage = 'en'
            }

            if (this.localLanguage == 'en') {
                this.$i18n.locale = 'en';
                localStorage.setItem('localLanguage', 'en')
            } else {
                this.$i18n.locale = country.localLanguage;
                localStorage.setItem('localLanguage', country.localLanguage)
            }
            // this.$router.go(0)
            // window.location.reload();
        },
        goindex() {
            if (!localStorage.getItem("country")) return
            this.switch_nav = 1
            this.$router.push({
                name: "index"
            }, () => {
                this.$nextTick(() => {
                    this.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            })
        },
        setNav() {
            var that = this
            this.country = localStorage.getItem("country") || ''
            if (localStorage.getItem("client_info")) {
                this.clientInfo = JSON.parse(localStorage.getItem("client_info"))
                // console.log('clientInfo==', this.clientInfo)
            }
            const currentRoute = this.$router.currentRoute;
            let path = currentRoute.path
            this.pathName = currentRoute.name
            // console.log('path========', currentRoute.path);
            if (path == '/Home') {
                this.nav = 1
            } else if (path == '/WhatsOn') {
                this.nav = 2
            } else if (path == '/Rewards') {
                this.nav = 3
            } else if (path == '/Module') {
                this.nav = 4
            } else if (path == '/MyAccount') {
                this.nav = 5
            }
        },
        gopersonalData_index() {
            var that = this
            this.switch_nav = 1
            if (localStorage.getItem("client_info")) {
                that.$router.push({
                    name: "personalData_index"
                }, () => {
                    that.$nextTick(() => {
                        that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    })
                })
            } else {
                that.$router.push({
                    name: "login_index",
                }, () => {
                    that.$nextTick(() => {
                        that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    })
                });
            }
        },
        gohome() {
            var that = this
            this.nav = 1
            var countryNameAbbreviation = JSON.parse(localStorage.getItem("country")).countryNameAbbreviation
            this.$router.push({ name: 'Home', params: { myParam: countryNameAbbreviation } }, () => {
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            });
        },
        goWhatsOn() {

            var that = this
            this.nav = 2
            that.$router.push({
                name: "WhatsOn"
            }, () => {
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            })
        },

        goRewards() {
            var that = this
            this.nav = 3
            that.$router.push({
                name: "Rewards"
            }, () => {
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            })
        },
        gocontactUs() {
            var that = this
            that.$router.push({
                name: "contactUs"
            }, () => {
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            })
        },
        goAuto_tech() {
            var that = this
            this.nav = 3
            that.$router.push({
                name: "Auto_tech"
            }, () => {
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            })
        },
        goModule() {
            var that = this
            this.nav = 4

            if (!localStorage.getItem("client_info")) {
                that.$router.push({
                    name: "Module"
                }, () => {
                    // that.$nextTick(() => {
                    //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    // })
                })
                return false
            }
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.signed_verifySigned,
                body: {
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.signed_verifySigned, '_res==', res)
                // 0未申请，1审核中，2审核通过
                localStorage.setItem("signed_verifySigned_contractStatus", res.resultData.contractStatus)

                // 0未申请，1审核中，2审核通过
                if (localStorage.getItem("signed_verifySigned_contractStatus") == 0 || !localStorage.getItem("clientToken")) {
                    that.$router.push({
                        name: "Module"
                    }, () => {
                        that.$nextTick(() => {
                            that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        })
                    })
                    return false
                } else if (localStorage.getItem("signed_verifySigned_contractStatus") == 1) {
                    that.$router.push({
                        name: "BSSList"
                    }, () => {
                        that.$nextTick(() => {
                            that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        })
                    })
                } else if (localStorage.getItem("signed_verifySigned_contractStatus") == 2) {
                    that.$router.push({
                        name: "BSSList"
                    }, () => {
                        that.$nextTick(() => {
                            that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        })
                    })
                } else {
                    that.$router.push({
                        name: "Module"
                    }, () => {
                        // that.$nextTick(() => {
                        //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        // })
                    })
                }


            })
        },
        gomanual_uploading() {
            var that = this
            this.nav = 4
            that.$router.push({
                name: "manual_uploading"
            }, () => {
                // that.$nextTick(() => {
                //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                // })
            })
        },
        goMyAccount() {
            // console.log('2222')
            var that = this
            if (localStorage.getItem("client_info")) {
                that.nav = 5
                that.$router.push({
                    name: "MyAccount",
                }, () => {
                    that.$nextTick(() => {
                        that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    })
                })
            } else {
                that.$router.push({
                    name: "login_index",
                }, () => {
                    that.$nextTick(() => {
                        that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    })
                });
            }

        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.select-title-yd {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 18px;
    font-size: 14px;
    color: #000;
}

.select-li-yd {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #4A4A4A;
}

.select-checkout-yd {
    color: #000;
}

.select-li-yd:hover {
    color: #007BBF;
}

.triangle-box-yd {
    position: absolute;
    top: 23px;
    left: 7px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #fff;
}

.select-box-yd {
    position: absolute;
    padding: 10px;
    top: 38px;
    left: -125px;
    width: 161px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.language-box-yd {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: start;
}

.language-icon-yd {
    width: 24px;
    height: 24px;
}




.select-title {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
    font-weight: bold;
}

.select-li {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #4A4A4A;
}

.select-checkout {
    color: #007BC0;
    background-color: #F4F9FF;
}

.select-li:hover {
    color: #007BBF;
}

.triangle-box {
    position: absolute;
    top: 48px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 14px solid #fff;
}

.select-box {
    position: absolute;
    padding: 10px;
    top: 61px;
    left: -150px;
    width: 190px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.language-box {
    position: relative;
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.language-icon {
    width: 43px;
    height: 43px;
}

.header-control {
    position: relative;
    left: 6px;
}

.pcShow .nav-d {
    padding-top: 10px;
    margin-left: -20px;
}

.AutoParts {
    font-weight: bolder;
    align-self: flex-end;
    padding-bottom: 10px;
    color: #000;
}

.nav-item {
    flex: initial;

}

.nav-item div {
    padding: 10px 20px;
}

.nav-item:hover {
    color: #007BBF;

}

.headerH {
    padding-top: 20px;
    // padding: 20px 0;
}

.scrollTop {
    animation-name: paddingChange;
    animation-duration: 0.5s;
    /* 动画持续时间 */
    animation-fill-mode: forwards;
    /* 动画结束后保持最后一帧的样式 */
    animation-timing-function: ease-out;
    padding: 50px 0;
}

@keyframes paddingChange {
    from {
        padding: 50px 0;
    }

    to {
        padding: 20px 0;
    }
}

.scrollbom {
    animation-name: paddingChange2;
    animation-duration: 0.1s;
    /* 动画持续时间 */
    animation-fill-mode: forwards;
    /* 动画结束后保持最后一帧的样式 */
    animation-timing-function: ease;
    padding: 20px 0;
}

@keyframes paddingChange2 {
    from {
        padding: 20px 0;
    }

    to {
        padding: 50px 0;
    }
}

.header_top {
    position: fixed;
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.icon {
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: -0.15em;
}

.svg-icon {
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: -0.15em;
}

.mobile-logo {
    width: 118px;
    height: auto;
}

.country_no {
    color: #000;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
}

.header {
    background: #fff;
}

.switch_nav_d {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    pointer-events: auto;
}

.switch_nav_bg {
    padding-top: 10px;
    width: 100%;
    flex: 1;
    background: #fff;

}

.switch_li {
    height: 66px;
    padding: 0 18px;
    box-sizing: border-box;
    border-bottom: 1px solid #F2F2F2;

}

.icon21 {
    width: 7px;
    height: auto;
}
</style>
